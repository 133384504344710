<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="border-0 p-3 d-flex justify-content-center map-container" style="min-height: 60px" @mouseleave="unsetHoveredStyles">
			<olMap :canvasFeatures="canvasFeatures" @mapReady="getMapContext" @layerAdded="cacheVectorLayer">
				<template #featureControls>
					<div class="toolbar">
						<ul class="nav pt-3 d-flex">
							<!-- <li v-b-tooltip.hover.bottom="'Show Beam Footprints'" class="nav-item mb-2 mx-2" @click="toggleTool('footprints', loadAllFootprints)">
								<span class="btn btn-sm btn-icon btn-bg-light btn-text-warning btn-hover-warning toolbar-icon" :class="{ 'btn-active': footprints }">
									<i class="flaticon-layers"></i>
								</span>
							</li>
							<li v-b-tooltip.hover.bottom="'Show Satellite Path'" class="nav-item mr-2" @click="toggleTool('satPaths')">
								<span class="btn btn-sm btn-icon btn-bg-light btn-text-warning btn-hover-warning sat-icon" :class="{ 'btn-active': satPaths }">
									<inline-svg src="/media/svg/custom/sat.svg" height="17" />
								</span>
							</li>
							<li v-b-tooltip.hover.bottom="'Show Remote Path'" class="nav-item mr-2" @click="toggleTool('remotePath', setRemotePath)">
								<span class="btn btn-sm btn-icon btn-bg-light btn-text-warning btn-hover-warning toolbar-icon" :class="{ 'btn-active': remotePath }">
									<div v-if="loadingRemotePath">
										<b-spinner />
									</div>
									<i v-else class="flaticon-map-location"></i>
								</span>
							</li> -->
						</ul>
					</div>
				</template>
			</olMap>

			<!-- OVERLAYS -->
			<!-- SATELLITE OVERLAY -->
			<div
				ref="satOverlay"
				class="satOverlay d-flex justify-content-center align-items-center"
				:style="{
					border: '1px solid ' + hoveredSat.color,
					'box-shadow': '2px 2px 10px -5px #000',
					visibility: overlayVisibility,
				}"
			>
				<div
					class="sat-arrow"
					:style="{
						'border-left': '1px solid ' + hoveredSat.color,
						'border-bottom': '1px solid ' + hoveredSat.color,
					}"
				></div>
				<div class="sat-name px-3">
					{{ hoveredSat.name }}
				</div>
			</div>
			<!-- MARKER OVERLAY -->
			<div
				ref="markerOverlay"
				class="marker-popup"
				:style="{
					visibility: overlayVisibility,
				}"
			>
				<div class="popover-arrow"></div>
				<div
					class="marker-popup-title"
					:style="{
						background: `linear-gradient(200deg, ${focusDevice.color[0]}, ${focusDevice.color[1]})`,
					}"
				>
					<h5>{{ focusDevice.name }}</h5>
				</div>
				<div class="marker-popup-body">
					<div>
						<span>GPS: </span><span class="marker-stat">{{ focusDevice.lat }}, {{ focusDevice.lon }}</span>
					</div>
					<div v-if="focusDevice.heading">
						<span>Heading: </span><span class="marker-stat">{{ focusDevice.heading }}&deg; </span>
					</div>
					<div v-if="focusDevice.heading">
						<span>Speed: </span><span class="marker-stat">{{ focusDevice.speed }} knots</span>
					</div>
					<div>
						<span>Current Network: </span><span class="marker-stat">{{ focusDevice.network }}</span>
					</div>
				</div>
			</div>
			<!-- BEAMCHANGE OVERLAY -->
			<div ref="beamchangeOverlay" class="beamchange-popup" :style="{ visibility: overlayVisibility }">
				<div class="popover-arrow"></div>
				<div class="marker-popup-title beamchange-popup-title">
					<h5>Beam Change</h5>
				</div>
				<div class="marker-popup-body">
					{{ bcOverlayText }}
				</div>
			</div>
		</div>

		<!-- NETWORK LEGEND -->
		<div id="networks-legend">
			<div v-if="footprints" class="d-flex justify-content-center flex-wrap">
				<div
					v-for="net in networks"
					:key="net.NetworkName"
					class="network d-flex align-items-center py-1 px-2 ml-4"
					@mouseover="highlightNetwork(net.NetworkName)"
					@mouseleave="unsetHoveredStyles"
				>
					<div class="network-color mr-2" :style="{ background: `rgba(${net.color.join(',')})` }"></div>
					<div>{{ net.NetworkName }}</div>
				</div>
			</div>
			<div v-else class="d-flex flex-column justify-content-center align-items-center flex-wrap">
				<div class="d-flex align-items-center justify-content-between w-100 px-4">
					<!-- <div class="download-csv" @click="downloadPathCSV"><i class="flaticon-download" /> Download Path Data CSV</div> -->
					<div class="scroll-note">Click map to zoom with scroll</div>
				</div>
				<div style="font-size: 1.2em" class="pb-2">
					{{ device.name }}
					: <span style="font-weight: 600">{{ device.lat }}, {{ device.lon }}</span>
					<span v-if="device.heading && device.speed"> - Heading {{ device.heading }}&deg; @ {{ device.speed }} knots</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { fromLonLat } from 'ol/proj';
import { Icon, Style, Fill, Stroke, Text } from 'ol/style';
import { asArray } from 'ol/color';
import Overlay from 'ol/Overlay';
import { mapGetters } from 'vuex';
import { downloadCSV } from '@/helpers';

//prettier-ignore
const colors = [
	'#0000FF','#FF9933','#660066','#FF3333','#009900','#333333','#99FFFF','#FFCCCC','#666666','#993300','#003300','#FF66FF','#660000','#666600','#66CC99','#FE4543','#9E5D11','#702311','#000099','#990099','#990000','#999900','#009900','#009999','#0033FF','#9900FF','#FF0066','#FF9900','#66FF00','#00FF99','#0066FF',
];

const setCanvasMarkers = (devices, statuses, deviceId) => {
	let features = [];
	let current = null;
	for (let device in devices) {
		// beamchanges
		if (devices[device]?.stats?.data?.beamchanges) {
			devices[device].stats.data.beamchanges.forEach(bc => {
				features.push({
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: fromLonLat([bc.gps.lon, bc.gps.lat]),
					},
					properties: {
						name: bc.text,
						status: 'BEAMCHANGE',
						type: 'beamchange',
					},
				});
			});
		}
		//site devices
		if (devices[device].stats?.data) {
			let d = {
				type: 'Feature',
				geometry: {
					type: 'Point',
					coordinates: fromLonLat([devices[device].stats.data.lon, devices[device].stats.data.lat]),
				},
				properties: {
					name: devices[device].Name,
					id: device,
					source: devices[device].Source,
					status: statuses[device].status,
					type: 'marker',
				},
			};
			if (device != deviceId) {
				features.push(d);
			} else {
				current = d;
			}
		}
	}
	if (current) features.push(current); //add current last so it will be on top of close markers
	return {
		name: 'markers',
		geojson: {
			type: 'FeatureCollection',
			features: features,
		},
		style: feature => {
			let srcs = {
				OK: '/media/markers/marker_green.png',
				ALARM: '/media/markers/marker.png',
				SCPC: '/media/markers/marker_blue.png',
				DEACTIVATED: '/media/markers/marker_white.png',
				BEAMCHANGE: '/media/svg/Custom/beamswitch.svg',
				default: '/media/markers/marker_grey.png',
			};
			let status = feature.getProperties().status;
			return new Style({
				image: new Icon({
					anchor: status == 'BEAMCHANGE' ? [0, 0] : [0.5, 1],
					anchorXUnits: 'fraction',
					anchorYUnits: 'fraction',
					src: srcs?.[status] || srcs['default'],
				}),
			});
		},
	};
};

const setCurrentFootprint = (networks, currentNet) => {
	let network = networks.find(net => net.NetworkId === currentNet);
	if (network && network.footprint) {
		return {
			name: 'currentFootprint',
			...constructPolyGeoJson([
				{
					id: network.NetworkId,
					name: network.NetworkName,
					footprint: network.footprint[0],
					color: asArray('blue'),
					type: 'footprint',
				},
			]),
		};
	} else {
		return {};
	}
};

const setConfiguredFootprints = networks => {
	let nets = [];
	networks.forEach(function(net) {
		if (!net.currentNet && net.footprint) {
			nets.push({
				footprint: net.footprint[0],
				id: net.NetworkId,
				name: net.NetworkName,
				color: asArray(net.color),
				type: 'footprint',
			});
		}
	});
	return {
		name: 'footprints',
		...constructPolyGeoJson(nets),
	};
};

const constructPolyGeoJson = networks => {
	let features = networks.map(net => {
		let over = false;
		let under = false;
		let path = net.footprint.path.map(coord => {
			if (coord.longitude > 170) over = true;
			if (coord.longitude < -170) under = true;
			return fromLonLat([coord.longitude, coord.latitude]);
		});
		if (over && under) {
			path = net.footprint.path.map(coord => {
				if (coord.longitude > 0) coord.longitude -= 360;
				return fromLonLat([coord.longitude, coord.latitude]);
			});
		}
		let properties = {};
		for (let prop in net) {
			if (prop !== 'footprint') {
				properties[prop] = net[prop];
			}
		}
		return {
			type: 'Feature',
			geometry: {
				type: 'Polygon',
				coordinates: [path],
			},
			properties,
		};
	});
	return {
		geojson: {
			type: 'FeatureCollection',
			features: features,
		},
		style: feature => {
			return new Style({
				stroke: new Stroke({
					color: feature.get('color'),
					width: 2,
				}),
				fill: new Fill({
					color: [0, 0, 0, 0],
				}),
			});
		},
	};
};

const setSatelliteMarkers = (networks, currentnet) => {
	return {
		name: 'satellites',
		geojson: {
			type: 'FeatureCollection',
			features: networks
				.filter(netf => netf.spacecraft && netf.spacecraft.Longitude)
				.map(net => {
					return {
						type: 'Feature',
						geometry: {
							type: 'Point',
							coordinates: fromLonLat([net.spacecraft.Longitude, 0]),
						},
						properties: {
							...net.spacecraft,
							type: 'satellite',
							color: net.NetworkId == currentnet ? 'blue' : 'black',
							pathColor: net.color,
						},
					};
				}),
		},
		style: feature =>
			new Style({
				image: new Icon({
					anchorXUnits: 'fraction',
					anchorYUnits: 'fraction',
					scale: 0.2,
					src: '/media/svg/custom/sat-white.svg',
					color: feature.get('color'),
				}),
			}),
	};
};

const setPathToSatellite = (networks, device) => {
	return {
		name: 'satPaths',
		geojson: {
			type: 'FeatureCollection',
			features: networks
				.filter(netf => netf.spacecraft && netf.spacecraft.Longitude)
				.map(net => {
					let spacelon = net.spacecraft.Longitude > 170 ? net.spacecraft.Longitude - 360 : net.spacecraft.Longitude;
					return {
						type: 'Feature',
						geometry: {
							type: 'LineString',
							coordinates: [fromLonLat([device.stats.data.lon, device.stats.data.lat]), fromLonLat([spacelon, 0])],
						},
						properties: {
							color: net.color,
						},
					};
				}),
		},
		style: feature => {
			return new Style({
				stroke: new Stroke({
					color: feature.get('color'),
					width: 2,
				}),
			});
		},
		visible: false,
	};
};

const overlayColors = {
	OK: ['#c4fce5', '#37c583'],
	ALARM: ['#f7ccd0', '#ED6B75'],
	DEACTIVATED: ['#e2eeff', '#bac3d1'],
	SCPC: ['#bdd9fc', '#659BE0'],
	default: ['#e2eeff', '#bac3d1'],
};

export default {
	name: 'NewtecMap',
	components: {
		olMap: () => import('@/view/content/lib/ol.vue'),
	},
	props: {
		deviceId: {
			type: Number,
			required: true,
		},
		devices: {
			type: Object,
			required: true,
		},
		statuses: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			map: null,
			canvasFeatures: [],
			footprints: false,
			vLayers: {},
			satPaths: false,
			remotePath: false,
			pathData: null,
			loadingRemotePath: false,
			hovered: [],
			satOverlay: null,
			markerOverlay: null,
			beamchangeOverlay: null,
			hoveredSat: {
				name: null,
				color: [],
			},
			bcOverlayText: null,
			overlayVisibility: 'hidden',
			focusDevice: {
				name: null,
				lat: null,
				lon: null,
				heading: null,
				speed: null,
				network: null,
				color: null,
			},
		};
	},
	computed: {
		...mapGetters(['DateRange']),
		networks() {
			if (!this.devices) return [];
			let nets = [];
			let i = 0;
			for (let device in this.devices) {
				if (this.devices[device]?.stats?.data) {
					this.devices[device].stats.data.networks.forEach(net => {
						if (!nets.find(n => n.NetworkName === net.NetworkName)) {
							if (!colors[i]) i = 0;
							let color = colors[i];
							let currentNet = this.devices[device].stats.data?.currentnet?.NetworkId === net.NetworkId ? true : false;
							nets.push({
								...net,
								color: asArray(color),
								currentNet,
							});
						}
						i++;
					});
				}
			}
			nets.sort((a, b) => {
				let nameA = a.NetworkName.toUpperCase();
				let nameB = b.NetworkName.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}
				return 0;
			});
			return nets;
		},
		device() {
			return {
				name: this.devices[this.deviceId].Name,
				...this.devices[this.deviceId].stats.data,
			};
		},
	},
	methods: {
		async toggleTool(tool, constructor = null) {
			if (this.vLayers[tool]) {
				this[tool] = !this.vLayers[tool].getVisible();
				this.vLayers[tool].setVisible(this[tool]);
			} else {
				this.canvasFeatures = [await constructor()];
				this[tool] = true;
			}
		},
		getMapContext(map) {
			this.map = map;
			this.map.getView().setCenter(fromLonLat([this.devices[this.deviceId].stats.data.gps.lon, this.devices[this.deviceId].stats.data.gps.lat]));
			this.satOverlay = new Overlay({
				element: this.$refs.satOverlay,
				positioning: 'center-left',
				stopEvent: false,
				offset: [22, -16],
			});
			this.markerOverlay = new Overlay({
				element: this.$refs.markerOverlay,
				positioning: 'center-center',
				stopEvent: false,
				offset: [12, -this.$refs.markerOverlay.clientHeight / 2],
			});
			this.beamchangeOverlay = new Overlay({
				element: this.$refs.beamchangeOverlay,
				positioning: 'center-left',
				stopEvent: false,
				offset: [35, -this.$refs.beamchangeOverlay.clientHeight / 2 + 11],
			});
			this.overlayVisibility = 'visible';
			this.map.addOverlay(this.satOverlay);
			this.map.addOverlay(this.markerOverlay);
			this.map.addOverlay(this.beamchangeOverlay);
			this.map.on('pointermove', event => this.handlePointermove(event));
		},
		cacheVectorLayer(layer) {
			this.vLayers = { ...layer, ...this.vLayers };
		},
		loadAllFootprints() {
			return setConfiguredFootprints(this.networks);
		},
		async getPathData() {
			let days = 0;
			if (this.DateRange.start && this.DateRange.end) {
				let start = new Date(this.DateRange.start);
				let end = new Date(this.DateRange.end);
				let diff = end.getTime() - start.getTime();
				days = Math.ceil(diff / (1000 * 3600 * 24));
				console.log(days);
			}
			let resp = await this.$http.get(`idirect/${this.devices[this.deviceId].Source}/gpspath/${days}`, { params: { limit: 500 } });
			this.pathData = resp.data.data;
		},
		async setRemotePath() {
			this.loadingRemotePath = true;
			if (!this.pathData) {
				await this.getPathData();
			}
			let paths = {
				name: 'remotePath',
				geojson: {
					type: 'FeatureCollection',
					features: [
						{
							type: 'Feature',
							geometry: {
								type: 'LineString',
								coordinates: this.pathData.path.map(p => {
									return fromLonLat([p.lon, p.lat]);
								}),
							},
						},
					],
				},
				style: new Style({
					stroke: new Stroke({
						color: 'blue',
						width: 2,
					}),
				}),
			};
			this.loadingRemotePath = false;
			return paths;
		},
		async downloadPathCSV() {
			if (!this.pathData) {
				await this.getPathData();
			}
			let csv = 'Timestamp,Lat,Lon,Heading\n';
			this.pathData.path.forEach(point => {
				csv += `${point.timestamp},${point.lat},${point.lon},${point?.heading || ''}\n`;
			});
			let date = new Date().toISOString().split('T');
			return downloadCSV(csv, `${this.devices[this.deviceId].Name}_GPS_Path ${date[0]}.csv`);

			// check for alternate modem name
			// angular.forEach($scope.site.layout.widgets, function(widget, wid) {
			// 	if (widget.DeviceId == $scope.col.DeviceId && widget.TypeId == 0 && widget.WidgetId == 2) {
			// 		if (typeof widget.properties !== 'undefined') {
			// 			if (typeof widget.properties.label !== 'undefined') {
			// 				if (widget.properties.label != '') {
			// 					nname = widget.properties.label;
			// 				}
			// 			}
			// 		}
			// 	}
			// });
			// saveAs(blob, nname + ' GPS Path ' + moment().format('YYYY-MM-DD h-mm A') + '.csv');
			// $scope.downloading_gps_csv = false;
		},
		handlePointermove(event) {
			this.unsetHoveredStyles();
			this.satOverlay.setPosition(undefined);
			this.beamchangeOverlay.setPosition(undefined);
			this.markerOverlay.setPosition(undefined);
			this.map.forEachFeatureAtPixel(event.pixel, feature => {
				switch (feature.get('type')) {
					case 'footprint':
						if (this.footprints) this.setFootprintHoveredStyle(feature);
						break;
					case 'satellite':
						this.hoveredSat.name = feature.get('SpacecraftName');
						this.hoveredSat.color = `rgba(${feature.get('pathColor').join(',')})`;
						this.satOverlay.setPosition(feature.getGeometry().getCoordinates());
						break;
					case 'marker':
						this.setFocusDevice(feature.get('id'));
						this.markerOverlay.setPosition(feature.getGeometry().getCoordinates());
						return true;
					case 'beamchange':
						this.bcOverlayText = feature.get('name');
						this.beamchangeOverlay.setPosition(feature.getGeometry().getCoordinates());
						break;
				}
			});
		},
		setFocusDevice(id) {
			let deviceInfo = this.devices[id].stats.data;
			this.focusDevice.name = this.devices[id].Name;
			this.focusDevice.lat = deviceInfo.lat;
			this.focusDevice.lon = deviceInfo.lon;
			this.focusDevice.heading = deviceInfo.bearing;
			this.focusDevice.speed = deviceInfo.speed;
			this.focusDevice.network = deviceInfo?.currentnet?.NetworkName;
			this.focusDevice.color = overlayColors[this.statuses[id].status] || overlayColors.default;
		},
		setFootprintHoveredStyle(feature) {
			this.hovered.push(feature);
			let color = feature.get('color');
			let fillColor = [...color];
			fillColor[3] = 0.4;
			feature.setStyle(
				new Style({
					stroke: new Stroke({
						color: color,
						width: 3,
					}),
					fill: new Fill({
						color: fillColor,
					}),
					text: new Text({
						text: feature.get('name'),
						font: '20px Arial',
						color: '#FFF',
						stroke: new Stroke({
							color: '#ffffff',
							width: 2,
						}),
					}),
				})
			);
		},
		unsetHoveredStyles() {
			if (this.hovered.length !== 0) {
				this.hovered.forEach(feature => feature.setStyle(undefined));
				this.hovered = [];
			}
		},
		highlightNetwork(networkName) {
			let found = false;
			this.vLayers.footprints.getSource().forEachFeature(feature => {
				if (feature.get('name') === networkName) {
					this.setFootprintHoveredStyle(feature);
					found = true;
				}
			});
			if (!found) {
				this.vLayers.currentFootprint.getSource().forEachFeature(feature => {
					if (feature.get('name') === networkName) {
						this.setFootprintHoveredStyle(feature);
					}
				});
			}
		},
		load() {
			// let t0 = performance.now();
			this.vLayers = {};
			this.canvasFeatures = [
				setCanvasMarkers(this.devices, this.statuses, this.deviceId),
				setCurrentFootprint(this.networks, this.device.currentnet?.NetworkId),
				setPathToSatellite(this.networks, this.devices[this.deviceId]),
				setSatelliteMarkers(this.networks, this.device.currentnet?.NetworkId),
			];
			this.setFocusDevice(this.deviceId);
			// let t1 = performance.now();
			// console.log('loaded in ' + (t1 - t0) + ' milliseconds');
		},
	},
	created() {
		if (Object.keys(this.devices).length > 0) {
			this.load();
		}
	},
	watch: {
		devices: {
			handler: function(newVal) {
				if (Object.keys(newVal).length > 0) {
					for (let layer in this.vLayers) {
						this.map.removeLayer(this.vLayers[layer]);
					}
					this.load();
					this.pathData = null;
					if (this.remotePath) {
						this.setRemotePath();
					}
				}
			},
		},
	},
};
</script>

<style scoped>
.map-container {
	position: relative;
	min-height: 200px;
	transition: all 300ms ease-in-out;
}
.satOverlay {
	height: 35px;
	position: absolute;
	border-radius: 5px;
	background: white;
}
.sat-name {
	font-weight: 500;
	white-space: nowrap;
}
.toolbar {
	position: absolute;
	z-index: 1;
	top: 0;
	background: white;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
}
.toolbar-icon i {
	color: grey;
}
.sat-icon svg {
	fill: grey;
}
.btn-active {
	background: #ffa800;
	border-color: #ffa800;
}
.btn-active:active {
	background: #9e6a01;
	border-color: #ffa800;
}
.btn-active.toolbar-icon i {
	color: white;
}
.btn-active.sat-icon svg,
.sat-icon:hover svg {
	fill: white;
}
#networks-legend {
	min-height: 35px;
}
.network {
	border: 1px solid #fff;
	cursor: pointer;
}
.network:hover {
	border: 1px solid rgba(0, 153, 255, 0.2);
	border-radius: 3px;
	background: rgba(0, 153, 255, 0.1);
}
.network-color {
	display: inline-block;
	height: 20px;
	width: 20px;
	border-radius: 20px;
}
.marker-popup,
.beamchange-popup {
	position: absolute;
	background: white;
	width: 250px;
	border-radius: 5px;
	box-shadow: 2px 4px 13px -5px rgb(99, 123, 124);
}
.beamchange-popup {
	min-height: 80px;
	box-shadow: 2px 4px 13px -5px #f1c670;
}
.popover-arrow {
	left: 0;
	position: absolute;
	top: 50%;
	background-color: #fff;
	transform: translate(-50%, -50%) rotate(45deg);
	height: 14px;
	width: 14px;
}
.sat-arrow {
	left: -1px;
	position: absolute;
	top: 50%;
	background-color: #fff;
	transform: translate(-50%, -50%) rotate(45deg);
	height: 12px;
	width: 12px;
}
.marker-popup-title {
	padding: 10px 10px;
	border-radius: 5px 5px 0 0;
}
.beamchange-popup-title {
	background: linear-gradient(#f8bb43, #f1c670);
	padding: 5px 10px;
}
.marker-popup-title h5 {
	margin: 0;
}
.marker-popup-body {
	min-height: 80px;
	padding: 10px;
}
.marker-stat {
	font-weight: 700;
}
.download-csv {
	color: #777;
	cursor: pointer;
}
.download-csv:hover {
	color: #3699ff;
}
.scroll-note {
	color: #777;
}
</style>
